import React from "react"

const PrivacyPolicy = () => {

    return (
        <div className="page-container">
            <div className='page-header'>
                <h2>Privacybeleid voor gebruikers en bezoekers van de website familievdgeest.nl</h2>
                <p>GraaffWorks is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.</p>
            </div>
            <div className="page-content" style={{flexDirection: 'column', width: '90%', paddingLeft: '50px', paddingRight: '50px', paddingBottom: '50px'}}>
                <h3>Persoonsgegevens die wij verwerken</h3>
                <p>GraaffWorks verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u deze zelf aan ons verstrekt. Hieronder vindt u een overzicht van de persoonsgegevens die wij verwerken:</p>
                <ul>
                    <li>Voor- en achternaam</li>
                    <li>Geslacht</li>
                    <li>Geboortedatum</li>
                    <li>Geboorteplaats</li>
                    <li>E-mailadres</li>
                </ul>
                <h3>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h3>
                <p> GraaffWorks verwerkt de volgende bijzondere en/of gevoelige persoonsgegevens van u:</p>
                <ul><li>gegevens van personen jonger dan 16 jaar Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als u er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via redactie@familievdgeest.nl, dan verwijderen wij deze informatie.</li></ul>

                <h3>Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h3>
            <p>GraaffWorks verwerkt uw persoonsgegevens voor de volgende doelen:</p>
            <ul>
                <li>Verzenden van onze nieuwsbrief en/of reclamefolder</li>
                <li>Actualiseren en conserveren van onze stamboom</li>


            </ul>

            <h3>Hoe lang we persoonsgegevens bewaren</h3>
            <p>GraaffWorks bewaart uw persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor uw gegevens worden verzameld.</p>
            
            <h3>Delen van persoonsgegevens met derden</h3>
            <p>GraaffWorks verkoopt uw gegevens niet aan derden en verstrekt deze uitsluitend indien dit nodig is voor de uitvoering van onze overeenkomst met u of om te voldoen aan een wettelijke verplichting. Met bedrijven die uw gegevens verwerken in onze opdracht, sluiten wij een bewerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van uw gegevens. GraaffWorks blijft verantwoordelijk voor deze verwerkingen.</p>
            
            <h3>Cookies, of vergelijkbare technieken, die wij gebruiken</h3>
            <p>Wij maken gebruik van cookies en vergelijkbare technieken om je ervaring op onze website te verbeteren en bepaalde functionaliteiten mogelijk te maken. Hieronder leggen we uit welke technieken we gebruiken en waarvoor.</p>
            <ul>
                <li>LocalStorage: LocalStorage is een techniek waarmee we gegevens in je browser opslaan. In tegenstelling tot cookies, worden gegevens in localStorage bewaard totdat je ze handmatig verwijdert of je browser deze opruimt. We gebruiken localStorage bijvoorbeeld om bepaalde voorkeuren of instellingen of inlogstatus, te onthouden wanneer je onze website opnieuw bezoekt.</li>
            </ul>

            <h3>Gegevens inzien, aanpassen of verwijderen</h3>
            <p>U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heeft u het recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw persoonsgegevens door GraaffWorks en heeft u het recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen om de persoonsgegevens die wij van u beschikken in een computerbestand naar u of een ander, door u genoemde organisatie, te sturen. U kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van uw persoonsgegevens of verzoek tot intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens sturen naar redactie@familievdgeest.nl. We reageren zo snel mogelijk, maar binnen twee weken, op uw verzoek.</p>

            <h3>Hoe wij persoonsgegevens beveiligen</h3>
            <p>GraaffWorks neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op via het <a href="/contact-form?subject=privacy">contactformulier</a> of via redactie@familievdgeest.nl</p> 
            </div>

        </div>
    );
};

export default PrivacyPolicy;