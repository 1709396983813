import { subset } from "d3";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    subject: '',
  });
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    
    const sub = searchParams.get("subject");
    setFormData({
      ...formData,
      subject: sub,
    });

  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, message, subject } = formData;
    try {
        const response = await fetch('https://api.jannekedegraaff.nl/api/geesten/contact-form', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name, email, message, subject }),
        });
        const data = await response.json();
        if (data.success) {
            alert('Bedankt voor uw bericht. We nemen zo snel mogelijk contact met u op.');
            setFormData({
                name: '',
                email: '',
                subject: '',
                message: '',
              });
          
        } else {
            alert('Er is een fout opgetreden. Probeer het later opnieuw.');
        }
      } catch (error) {
            alert('Er is een fout opgetreden. Probeer het later opnieuw.');
          console.error(error);

      }
    // Hier kun je de gegevens van het formulier verzenden naar een backend of een e-mailadres
  };

  return (
    <div className="page-container" style={{paddingTop: '100px'}}>
      <div className="logo"><img src="/darkblue.png" alt="Logo" width={150} style={{position: 'absolute', top: '10px', left: '10px'}}/></div>
      <form className="login-form" onSubmit={handleSubmit}>
      <h2>Contact formulier</h2>
      <p>Heeft u op of aanmerkingen? Neem dan contact op met ons. U kunt ook een e-mail sturen naar redactie@familievdgeest.nl</p>
        <div className="form-group">
          <label htmlFor="name">Naam:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">E-mailadres:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Onderwerp</label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Bericht:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Verstuur</button>
      </form>
    </div>
  );
};

export default ContactForm;