import React, { useEffect, useRef, useState } from 'react';
import './familytree.css';
import FamilyTree from '@balkangraph/familytree.js';
  
const familyNodes = [
    { id: 0, pids: [1], gender: 'male', name: 'Antoon van der Geest', fullName: 'Antonius Simon van der Geest', birthDate: '1903-02-27', deathDate: '1978', photo: require('../assets/images/6606-male-user-256.png'), city: "Oud Ade", country: "Nederland"}, 
    { id: 1, pids: [0], gender: 'female', name: 'Kee van der Poel', fullName: 'Cornelia Anna van der Poel', birthDate: '1905-03-18', deathDate: '2000', photo: require('../assets/images/6607-female-user-256.png'), city: "Oud Ade", country: "Nederland"},
    { id: 3, pids: [12], divorced: [12], gender: 'male', name: 'Koos van der Geest', fullName: 'Jacobus Maria van der Geest', fid: 0, mid: 1, birthDate: '1930-10-11', deathDate: '2016', photo: require('../assets/images/6606-male-user-256.png'), city: "Leidschendam", country: "Nederland"},
    { id: 4, pids: [19], gender: 'male', name: 'Jan van der Geest', fullName: 'Johannes Jakobus van der Geest', fid: 0, mid: 1, birthDate: '1932-02-28', photo: require('../assets/images/6606-male-user-256.png'), city: "Leidschendam", country: "Nederland"},
    { id: 5, pids: [], gender: 'male', name: 'Kees van der Geest', fullName: 'Cornelis Adrianus van der Geest', fid: 0, mid: 1, birthDate: '1933-09-05', photo: require('../assets/images/6606-male-user-256.png'), city: "Leidschendam", country: "Nederland"},
    { id: 6, pids: [23], gender: 'female', name: 'Annie van der Geest', fullName: 'Anna Geertruida Wilhelmina van der Geest', fid: 0, mid: 1, birthDate: '1935-06-01', photo: require('../assets/images/6607-female-user-256.png'), city: "Leidschendam", country: "Nederland"},
    { id: 7, pids: [], gender: 'male', name: 'Ted van der Geest', fullName: 'Antonius Christianus van der Geest', fid: 0, mid: 1, birthDate: '1937-05-16', photo: require('../assets/images/6606-male-user-256.png'), city: "Leidschendam", country: "Nederland"},
    { id: 8, pids: [28], gender: 'female', name: 'Cock van der Geest', fullName: 'Cornelia Maria van der Geest', fid: 0, mid: 1, birthDate: '1938-06-11', photo: require('../assets/images/6607-female-user-256.png'), city: "Leidschendam", country: "Nederland"},
    { id: 9, pids: [], gender: 'male', name: 'Dick van der Geest', fullName: 'Theodorus Jacobus Maria van der Geest', fid: 0, mid: 1, birthDate: '1941-07-26', photo: require('../assets/images/6606-male-user-256.png'), city: "Leidschendam", country: "Nederland"},
    { id: 10, pids: [33], gender: 'male', name: 'Sjaak van der Geest', fullName: 'Jacobus Damianus Maria van der Geest', fid: 0, mid: 1, birthDate: '1943-06-19', photo: require('../assets/images/6606-male-user-256.png'), city: "Leidschendam", country: "Nederland"},
    { id: 11, pids: [37], divorced: [37], gender: 'female', name: 'Ria van der Geest', fullName: 'Maria Hillegonda Geertruida van der Geest', fid: 0, mid: 1, birthDate: '1945-04-23', photo: require('../assets/images/6607-female-user-256.png'), city: "Leidschendam", country: "Nederland"},
    { id: 12, pids: [3], divorced: [3], gender: 'female', name: 'Toos Schipper', fullName: 'Catharina Hendrika Schipper', birthDate: '1931-08-20', deathDate: '2023',photo: require('../assets/images/profile_pictures/toos_schipper.jpeg'), city: "Leidschendam", country: "Nederland"},
    { id: 13, pids: [39, 40], divorced: [39], gender: 'female', name: 'Anita van der Geest', fullName: 'Johanna Cornelia Maria van der Geest', fid: 3, mid: 12, birthDate: '1956-03-11', photo: require('../assets/images/6607-female-user-256.png'), city: "Leidschendam", country: "Nederland"},
    { id: 14, pids: [43], gender: 'male', name: 'Fred van der Geest', fullName: 'Fredericus Johannes Franciscus van der Geest', fid: 3, mid: 12, birthDate: '1957-07-05', photo: require('../assets/images/6606-male-user-256.png'), city: "Leidschendam", country: "Nederland"},
    { id: 15, pids: [48], gender: 'female', name: 'Petra van der Geest', fullName: 'Petronella Maria van der Geest', fid: 3, mid: 12, birthDate: '1959-07-19', photo: require('../assets/images/6607-female-user-256.png'), city: "Leidschendam", country: "Nederland"},
    { id: 16, pids: [52], gender: 'male', name: 'Ton van der Geest', fullName: 'Antonius Jacobus van der Geest', fid: 3, mid: 12, birthDate: '1961-01-30', photo: require('../assets/images/6606-male-user-256.png'), city: "Leidschendam", country: "Nederland" },
    { id: 17, pids: [56], gender: 'female', name: 'Miriam van der Geest', fullName: 'Maria Catharina van der Geest', fid: 3, mid: 12, birthDate: '1963-04-19', photo: require('../assets/images/profile_pictures/miriam_van_der_geest.jpeg'), city: "Leidschendam", country: "Nederland" },
    { id: 18, pids: [59], gender: 'male', name: 'Paul van der Geest', fullName: 'Paulus Jacobus van der Geest', fid: 3, mid: 12, birthDate: '1964-10-28', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 19, pids: [4], gender: 'female', name: 'Ria Romijn', fullName: 'Maria Johanna Helena Romijn', birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 20, pids: [75], divorced: [75], gender: 'female', name: 'Corinne van der Geest', fullName: 'Cornelia Catharina van der Geest', fid: 4, mid: 19, birthDate: '1963-11-16', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 21, pids: [79], gender: 'female', name: 'Lonneke van der Geest', fullName: 'Apolonia Helena Maria van der Geest', fid: 4, mid: 19, birthDate: '1965-12-29', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 22, pids: [84], gender: 'female', name: 'Judith van der Geest', fullName: 'Judith Petronella van der Geest', fid: 4, mid: 19, birthDate: '1969-09-10', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 23, pids: [6], gender: 'male', name: 'Frans Zonneveld', fullName: 'Frans Zonneveld', birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 24, pids: [89], gender: 'female', name: 'Jenny Zonneveld', fullName: 'Jenny Zonneveld', fid: 23, mid: 6, birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 25, pids: [94], gender: 'female', name: 'Anja Zonneveld', fullName: 'Anja Zonneveld', fid: 23, mid: 6, birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 26, pids: [99], gender: 'male', name: 'Frans Zonneveld', fullName: 'Frans Zonneveld', fid: 23, mid: 6, birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 27, pids: [102], gender: 'female', name: 'Maret Zonneveld', fullName: 'Maret Zonneveld', fid: 23, mid: 6, birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 28, pids: [8], gender: 'male', name: 'Paul Groenewegen', fullName: 'Paul Groenewegen', birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 29, pids: [], gender: 'male', name: 'Rob Groenewegen', fullName: 'Rob Groenewegen', fid: 28, mid: 8, birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 30, pids: [], gender: 'male', name: 'Eric Groenewegen', fullName: 'Eric Groenewegen', fid: 28, mid: 8, birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 31, pids: [110], gender: 'male', name: 'Fons Groenewegen', fullName: 'Fons Groenewegen', fid: 28, mid: 8, birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 32, pids: [114], gender: 'female', name: 'Inge Groenewegen', fullName: 'Inge Groenewegen', fid: 28, mid: 8, birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 33, pids: [10], gender: 'female', name: 'Betty van der Weij', fullName: 'Betty van der Weij', birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 34, pids: [115], gender: 'female', name: 'Rimke van der Geest', fullName: 'Rimke van der Geest', fid: 10, mid: 33, birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 35, pids: [118], divorced: [118], gender: 'male', name: 'Kees van der Geest', fullName: 'Kees van der Geest', fid: 10, mid: 33, birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 36, pids: [120], gender: 'female', name: 'Geertje van der Geest', fullName: 'Geertje van der Geest', fid: 10, mid: 33, birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 37, pids: [11], divorced: [11], gender: 'male', name: 'Harm Houwing', fullName: 'Harm Houwing', birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 38, pids: [123], gender: 'male', name: 'Daan Houwing', fullName: 'Daan Houwing', fid: 37, mid: 11, birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 39, pids: [13], divorced: [13], gender: 'male', name: 'Sjaak Veraart', fullName: 'Jaques Veraart', birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 40, pids: [13], gender: 'male', name: 'Marco Westland', fullName: 'Marco Westland', birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 41, pids: [], gender: 'male', name: 'Frank Veraart', fullName: 'Frank Veraart', fid: 39, mid: 13, birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 42, pids: [], gender: 'male', name: 'Bart Veraart', fullName: 'Bart Veraart', fid: 39, mid: 13, birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 43, pids: [14], gender: 'female', name: 'Lilly Hooijmans', fullName: 'Lilly Hooijmans', birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 44, pids: [69], gender: 'female', name: 'Anouk van der Geest', fullName: 'Anouk van der Geest', fid: 14, mid: 43, birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 45, pids: [71], gender: 'male', name: 'Kay van der Geest', fullName: 'Kay van der Geest', fid: 14, mid: 43, birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 46, pids: [74], gender: 'male', name: 'Bas van der Geest', fullName: 'Bas van der Geest', fid: 14, mid: 43, birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 47, pids: [], gender: 'female', name: 'Ronja van der Geest', fullName: 'Ronja van der Geest', fid: 14, mid: 43, birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 48, pids: [15], gender: 'male', name: 'Edu Verlinden', fullName: 'Edu Verlinden', birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 49, pids: [60], gender: 'male', name: 'Davy Verlinden', fullName: 'Davy Verlinden', fid: 48, mid: 15, birthDate: '1991-04-26', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 50, pids: [64], gender: 'female', name: 'Jill Verlinden', fullName: 'Jill Verlinden', fid: 48, mid: 15, birthDate: '1992-09-17', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 51, pids: [67], gender: 'male', name: 'Mike Verlinden', fullName: 'Mike Verlinden', fid: 48, mid: 15, birthDate: '1995-04-30', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 52, pids: [16], gender: 'female', name: 'Petra Weerdenburg', fullName: 'Petra Weerdenburg', birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 53, pids: [], gender: 'male', name: 'Ingmar vn der Geest', fullName: 'Ingmar van der Geest', fid: 16, mid: 52, birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 54, pids: [68], gender: 'male', name: 'Sten vn der Geest', fullName: 'Sten van der Geest', fid: 16, mid: 52, birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 55, pids: [], gender: 'female', name: 'Elin vn der Geest', fullName: 'Elin van der Geest', fid: 16, mid: 52, birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 56, pids: [17], gender: 'male', name: 'Richard de Graaff', fullName: 'Richard de Graaff', birthDate: '1962-08-17', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 57, pids: [], gender: 'female', name: 'Janneke de Graaff', fullName: 'Janneke de Graaff', fid: 56, mid: 17, birthDate: '', photo: require('../assets/images/profile_pictures/janneke_de_graaff.jpeg')},
    { id: 58, pids: [], gender: 'male', name: 'Tim de Graaff', fullName: 'Tim de Graaff', fid: 56, mid: 17, birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 59, pids: [18], gender: 'female', name: 'Cocky de Wolf', fullName: 'Cocky de Wolf', birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 60, pids: [49], gender: 'female', name: 'Renata Jablanovic', fullName: 'Renata Jablanovic', birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 61, pids: [], gender: 'female', name: 'Atiana Engelbracht', fullName: 'Atiana Engelbracht', mid: 60, birthDate: '2009-01-30', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 62, pids: [], gender: 'female', name: 'Lara Verlinden', fullName: 'Lara Verlinden', fid: 49, mid: 60, birthDate: '2018-07-28', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 63, pids: [], gender: 'male', name: 'Kay Verlinden', fullName: 'Kay Verlinden' ,fid: 49, mid: 60, birthDate: '2020-05-13', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 64, pids: [50], gender: 'male', name: 'Mats Harteveld', fullName: 'Mats Harteveld', birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 65, pids: [], gender: 'male', name: 'Lenn Harteveld', fullName: 'Lenn Harteveld', fid: 64, mid: 50, birthDate: '2022-10-31', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 66, pids: [], gender: 'female', name: 'Veda Harteveld', fullName: 'Veda Harteveld', fid: 64, mid: 50, birthDate: '2024-08-29', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 67, pids: [51], gender: 'female', name: 'Miera Radhe', fullName: 'Miera Radhe', birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 68, pids: [54], gender: 'female', name: 'Nava', fullName: 'Nava', birthDate: '1995-08-08', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 69, pids: [44], gender: 'male', name: 'Cat Coman', fullName: 'Catalin Coman', birthDate: '1991-11-12', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 70, pids: [], gender: 'male', name: 'Otis Coman', fullName: 'Otis Coman', fid: 69, mid: 44, birthDate: '2024-02-06', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 71, pids: [45], gender: 'female', name: 'Benolo Baker', fullName: 'Benolo Baker', birthDate: '1990-11-12', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 72, pids: [], gender: 'male', name: 'Kian van der Geest', fullName: 'Kian van der Geest', fid: 45, mid: 71, birthDate: '2021-02-06', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 73, pids: [], gender: 'female', name: 'Eliana van der Geest', fullName: 'Eliana van der Geest', fid: 45, mid: 71, birthDate: '2024-07-19', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 74, pids: [46], gender: 'female', name: 'Denise Stevens', fullName: 'Denise Stevens', birthDate: '1994-05-15', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 75, pids: [20], divorced: [20], gender: 'male', name: 'Bart van Oosterhout', fullName: 'Bart van Oosterhout', birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 76, pids: [], gender: 'male', name: 'Jasper van Oosterhout', fullName: 'Jasper van Oosterhout', fid: 75, mid: 20, birthDate: '1999-07-31', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 77, pids: [78], gender: 'female', name: 'Marijke van Oosterhout', fullName: 'Marijke van Oosterhout', fid: 75, mid: 20, birthDate: '1996-02-11', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 78, pids: [77], gender: 'male', name: 'Koen', fullName: 'Koen', birthDate: '1991-03-31', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 79, pids: [21], gender: 'male', name: 'Hans Heuvelman', fullName: 'Hans Heuvelman', birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 80, pids: [82], gender: 'female', name: 'Dionne Heuvelman', fullName: 'Dionne Heuvelman', fid: 79, mid: 21, birthDate: '1997-08-28', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 81, pids: [83], gender: 'female', name: 'Janneke Heuvelman', fullName: 'Janneke Heuvelman', fid: 79, mid: 21, birthDate: '1999-11-12', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 82, pids: [80], gender: 'male', name: 'Floris', fullName: 'Floris', birthDate: '1995-12-30', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 83, pids: [81], gender: 'male', name: 'Tim', fullName: 'Tim', birthDate: '1999-05-30', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 84, pids: [22], gender: 'male', name: 'Henk Janssen', fullName: 'Hans Janssen', birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 85, pids: [88], gender: 'female', name: 'Meike Janssen', fullName: 'Meike Janssen', fid: 84, mid: 22, birthDate: '2001-08-31', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 86, pids: [], gender: 'male', name: 'Stijn Janssen', fullName: 'Stijn Janssen', fid: 84, mid: 22, birthDate: '2003-10-25', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 87, pids: [], gender: 'female', name: 'Puck Janssen', fullName: 'Puck Janssen', fid: 84, mid: 22, birthDate: '2009-09-25', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 88, pids: [85], gender: 'male', name: 'Thomas', fullName: 'Thomas', birthDate: '1997-12-30', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 89, pids: [24], gender: 'male', name: 'Jan Willem Boiten', fullName: 'Jan Willem Boiten', birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 90, pids: [91], gender: 'female', name: 'Dorien Boiten', fullName: 'Dorien Boiten', fid: 89, mid: 24, birthDate: '1996-08-14', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 91, pids: [90], gender: 'male', name: 'Thomas', fullName: 'Thomas', birthDate: '1995-12-19', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 92, pids: [93], gender: 'female', name: 'Wieke Boiten', fullName: 'Wieke Boiten', fid: 89, mid: 24, birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 93, pids: [92], gender: 'male', name: 'Kyle', fullName: 'Kyle', birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 94, pids: [25], gender: 'male', name: 'Marco Zanen', fullName: 'Marco Zanen', birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 95, pids: [], gender: 'male', name: 'Joep Zanen', fullName: 'Joep Zanen', fid: 94, mid: 25, birthDate: '1993-01-10', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 96, pids: [97], gender: 'female', name: 'Lucie Zanen', fullName: 'Lucie Zanen', fid: 94, mid: 25, birthDate: '1995-03-23', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 97, pids: [96], gender: 'male', name: 'Niels', fullName: 'Niels', birthDate: '1993-09-05', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 98, pids: [], gender: 'male', name: 'Olaf Zanen', fullName: 'Olaf Zanen', fid: 94, mid: 25, birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 99, pids: [26], gender: 'female', name: 'Jet de Jonge', fullName: 'Jet de Jonge', birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 100, pids: [], gender: 'female', name: 'Anne Zonneveld', fullName: 'Anne Zonneveld', fid: 26, mid: 99, birthDate: '1999-04-19', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 101, pids: [], gender: 'female', name: 'Maud Zonneveld', fullName: 'Maud Zonneveld', fid: 26, mid: 99, birthDate: '2002-01-25', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 102, pids: [27], gender: 'male', name: 'Colijn van Noort', fullName: 'Colijn van Noort', birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 103, pids: [], gender: 'male', name: 'Pim van Noort', fullName: 'Pim van Noort', fid: 102, mid: 27, birthDate: '1999-07-25', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 104, pids: [105], gender: 'female', name: 'Roos van Noort', fullName: 'Roos van Noort', fid: 102, mid: 27, birthDate: '2001-08-26', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 105, pids: [104], gender: 'male', name: 'Wouter', fullName: 'Wouter', birthDate: '1999-12-22', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 106, pids: [107], gender: 'female', name: 'Floor van Noort', fullName: 'Floor van Noort', fid: 102, mid: 27, birthDate: '2002-12-25', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 107, pids: [106], gender: 'male', name: 'Hidde', fullName: 'Hidde', birthDate: '2000-06-10', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 108, pids: [109], gender: 'male', name: 'Jan van Noort', fullName: 'Jan van Noort', fid: 102, mid: 27, birthDate: '2005-07-29', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 109, pids: [108], gender: 'female', name: 'Mijke', fullName: 'Mijke', birthDate: '2003-05-2003', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 110, pids: [31], gender: 'female', name: 'Susan Frantzen', fullName: 'Susan Frantzen', birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 111, pids: [112], gender: 'female', name: 'Robyn Groenewegen', fullName: 'Robyn Groenewegen', fid: 31, mid: 110, birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 112, pids: [111], gender: 'male', name: 'Daan Schipper', fullName: 'Daan Schipper', birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 113, pids: [], gender: 'male', name: 'Jordan Groenewegen', fullName: 'Jordan Groenewegen', fid: 31, mid: 110, birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 114, pids: [32], gender: 'male', name: 'Ferdinand Horst', fullName: 'Ferdinand Horst', birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 115, pids: [34], gender: 'male', name: 'Gerben Wieldraaijer', fullName: 'Gerben Wieldraaijer', birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 116, pids: [], gender: 'male', name: 'Sigur Wieldraaijer', fullName: 'Sigur Wieldraaijer', birthDate: '2015-12-28', deathDate: '2016-01-13', fid: 115, mid: 34, photo: require('../assets/images/6606-male-user-256.png')},
    { id: 117, pids: [], gender: 'male', name: 'Jukka Wieldraaijer', fullName: 'Jukka Wieldraaijer', birthDate: '2016-12-30', fid: 115, mid: 34, photo: require('../assets/images/6606-male-user-256.png')},
    { id: 118, pids: [35], divorced: [35], gender: 'female', name: 'Eva Espinoza Sanchez', fullName: 'Eva Espinoza Sanchez', birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 119, pids: [], gender: 'male', name: 'Ruben van der Geest', fullName: 'Ruben van der Geest', birthDate: '2009-09-04', fid: 35, mid: 118, photo: require('../assets/images/6606-male-user-256.png')},
    { id: 120, pids: [36], gender: 'male', name: 'Jesse de Rooij', fullName: 'Jesse de Rooij', birthDate: '', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 121, pids: [], gender: 'female', name: 'Tinne-Lou de Rooij', fullName: 'Tinne-Lou de Rooij', birthDate: '2010-09-23', fid: 120, mid: 36, photo: require('../assets/images/6607-female-user-256.png')},
    { id: 122, pids: [], gender: 'female', name: 'Akse de Rooij', fullName: 'Akse de Rooij', birthDate: '2012-09-30', fid: 120, mid: 36, photo: require('../assets/images/6607-female-user-256.png')},
    { id: 123, pids: [38], gender: 'female', name: 'Esther de Ruyter', fullName: 'Esther de Ruyter', birthDate: '', photo: require('../assets/images/6607-female-user-256.png')},
    { id: 124, pids: [], gender: 'male', name: 'Tim Houwing', fullName: 'Tim Houwing', fid: 38, mid: 123, birthDate: '1999-03-02', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 125, pids: [], gender: 'male', name: 'Luc Houwing', fullName: 'Luc Houwing', fid: 38, mid: 123, birthDate: '2002-01-15', photo: require('../assets/images/6606-male-user-256.png')},
    { id: 126, pids: [], gender: 'male', name: 'Bas Houwing', fullName: 'Bas Houwing', fid: 38, mid: 123, birthDate: '2003-10-15', photo: require('../assets/images/6606-male-user-256.png')},
]
  // Hoofdcomponent
  const FamTree = () => {
    const treeRef = useRef(null); // Gebruik een ref voor de div
    const role =  JSON.parse(localStorage.getItem('auth')).role;
    const [familyObject, setFamilyObject] = useState(null);

    const handlePdfDownload = () => {
        familyObject.exportPDF();
    };

    function getDisplayName(node) {
        return node.deathDate ? `${node.name} (\u271D)` : node.name;
    }

    useEffect(() => {
        console.log('role', role);
      if (treeRef.current) {
        FamilyTree.templates.custom = Object.assign({}, FamilyTree.templates.hugo);
        FamilyTree.templates.custom.defs = `
            <g transform="matrix(0.05,0,0,0.05,-12,-9)" id="heart">
                <path fill="#aeaeae" d="M438.482,58.61c-24.7-26.549-59.311-41.655-95.573-41.711c-36.291,0.042-70.938,15.14-95.676,41.694l-8.431,8.909  l-8.431-8.909C181.284,5.762,98.663,2.728,45.832,51.815c-2.341,2.176-4.602,4.436-6.778,6.778 c-52.072,56.166-52.072,142.968,0,199.134l187.358,197.581c6.482,6.843,17.284,7.136,24.127,0.654 c0.224-0.212,0.442-0.43,0.654-0.654l187.29-197.581C490.551,201.567,490.551,114.77,438.482,58.61z"/>
            </g>

            <g transform="matrix(0.05,0,0,0.05,-12,-9)" id="broken-heart" fill="#aeaeae">
                <path fill="#E4E9F7" d="M438.482,58.61c-24.7-26.549-59.311-41.655-95.573-41.711c-36.291,0.042-70.938,15.14-95.676,41.694l-8.431,8.909  l-8.431-8.909C181.284,5.762,98.663,2.728,45.832,51.815c-2.341,2.176-4.602,4.436-6.778,6.778 c-52.072,56.166-52.072,142.968,0,199.134l187.358,197.581c6.482,6.843,17.284,7.136,24.127,0.654 c0.224-0.212,0.442-0.43,0.654-0.654l187.29-197.581C490.551,201.567,490.551,114.77,438.482,58.61z"/>
                <path fill="#aeaeae" d="M473.7 73.8l-2.4-2.5c-46-47-118-51.7-169.6-14.8L336 159.9l-96 64 48 128-144-144 96-64-28.6-86.5C159.7 19.6 87 24 40.7 71.4l-2.4 2.4C-10.4 123.6-12.5 202.9 31 256l212.1 218.6c7.1 7.3 18.6 7.3 25.7 0L481 255.9c43.5-53 41.4-132.3-7.3-182.1z"/>
            </g>

            <g transform="matrix(0.05,0,0,0.05,-12,-9)" id="dot">
                <path fill="#aeaeae" d="M438.482,58.61c-24.7-26.549-59.311-41.655-95.573-41.711c-36.291,0.042-70.938,15.14-95.676,41.694l-8.431,8.909  l-8.431-8.909C181.284,5.762,98.663,2.728,45.832,51.815c-2.341,2.176-4.602,4.436-6.778,6.778 c-52.072,56.166-52.072,142.968,0,199.134l187.358,197.581c6.482,6.843,17.284,7.136,24.127,0.654 c0.224-0.212,0.442-0.43,0.654-0.654l187.29-197.581C490.551,201.567,490.551,114.77,438.482,58.61z"/>
            </g>
        `;

        FamilyTree.templates.custom_male = Object.assign({}, FamilyTree.templates.hugo);
        FamilyTree.templates.custom_female = Object.assign({}, FamilyTree.templates.hugo);
        FamilyTree.templates.custom_female.node = `<rect x="0" y="0" height="{h}" width="{w}" stroke-width="1" fill="#E5AFB2" stroke="#aeaeae" rx="7" ry="7"></rect>`;
        FamilyTree.templates.custom_male.node = `<rect x="0" y="0" height="{h}" width="{w}" stroke-width="1" fill="#9FBEE2" stroke="#aeaeae" rx="7" ry="7"></rect>`;
        FamilyTree.templates.custom_female.field_0 = `<text data-width="230" style="font-size: 18px;font-weight:bold;" fill="#ffffff" x="125" y="85" text-anchor="middle">{val}</text>`

        const isMobile = () => {
            return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(navigator.userAgent || navigator.vendor || window.opera) || 
                   /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4));
        };

        // Initialiseer de FamilyTree grafiek wanneer de ref is gekoppeld aan het DOM-element
        const family = new FamilyTree(treeRef.current, {
            // mouseScrool: FamilyTree.action.none,
            scaleInitial: FamilyTree.match.height,
            zoom: {speed: 10, smooth: 10},
            // nodeMouseClick: FamilyTree.action.none,
            partnerChildrenSplitSeparation: 40,
            levelSeparation: 300,
            // menu: {
            //     pdf: { text: "Export PDF" },
            //     png: { text: "Export PNG" },
            //     svg: { text: "Export SVG" },
                
            //     xml: { text: "Export XML" },
            //     json: { text: "Export JSON" }
            // },
            toolbar: {
                zoom: true,
                fit: true,
            },
            miniMap: !isMobile(),
            editForm: {
                readOnly: role === 'user',
                titleBinding: "name",
                photoBinding: "photo",
                generateElementsFromFields: false,
                elements: [
                    { type: 'textbox', label: 'Volledige naam', binding: 'fullName' },

                    [
                        { type: 'date', label: 'Geboortedatum', binding: 'birthDate' },
                        { type: 'date', label: 'Sterfdatum', binding: 'deathDate' }
                    ],
                    [
                        { type: 'select', label: 'Land', binding: 'country' },
                        { type: 'textbox', label: 'Stad', binding: 'city' },
                    ],
                    { type: 'textbox', label: 'Phone', binding: 'Mobile' },
                ],
                buttons:  {
                    edit: {
                        hideIfDetailsMode: role === 'user',
                    },
                    share: null,
                    pdf: null
                }
            },
            template: "custom",
            nodeBinding: {
                field_0: "name",
                field_1: "birthDate",
                img_0: "photo"
            },
            
            nodes: familyNodes,

        });

        family.on('field', function (sender, args) {
            if (args.name === 'birthDate') {
                var date = new Date(args.value);
                args.value = date.toLocaleDateString();
            }
            if (args.name === 'name') {
                args.value = getDisplayName(args.data);
            }
        });

        family.on('expcollclick', function (sender, isCollapsing, nodeId) {
            var node = family.getNode(nodeId);
            if (isCollapsing) {
                family.expandCollapse(nodeId, [], node.ftChildrenIds)
            }
            else {
                family.expandCollapse(nodeId, node.ftChildrenIds, [])
            }
            return false;
        });

        family.onInit(() => {
            // family.collapse(1, [6, 9, 10]);
        });

        family.on('init', function(sender, args){
            // hide second generation
            // var node = family.getNode(3);
            //     family.expandCollapse(3, [], node.ftChildrenIds);

            // var node = family.getNode(4);
            //     family.expandCollapse(4, [], node.ftChildrenIds);
            
            // var node = family.getNode(6);
            //     family.expandCollapse(6, [], node.ftChildrenIds);
            
            // var node = family.getNode(8);
            //     family.expandCollapse(8, [], node.ftChildrenIds);
            
            // var node = family.getNode(10);
            //     family.expandCollapse(10, [], node.ftChildrenIds);
            
            // var node = family.getNode(11);
            //     family.expandCollapse(11, [], node.ftChildrenIds);
                

            
        })

        family.on('render-link', function(sender, args){
            var cnodeData = family.get(args.cnode.id); 
            var nodeData = family.get(args.node.id);
      
                if (cnodeData.divorced !== undefined && nodeData.divorced !== undefined &&
                cnodeData.divorced.includes(args.node.id) && nodeData.divorced.includes(args.cnode.id)) {
                    args.html = args.html.replace("path", "path stroke-dasharray='3, 2'");
                }
        });

        family.on('render-link', function (sender, args) {
            const nodeData = family.get(args.node.id);

            if (args.cnode.ppid !== undefined && (!nodeData.divorced))
                args.html += `<use data-ctrl-ec-id="${args.node.id}" xlink:href="#heart" x="${args.p.xa}" y="${args.p.ya}"/>`;
            if (args.cnode.isPartner && args.node.partnerSeparation === 30 && (!nodeData.divorced))
                args.html +=  `<use data-ctrl-ec-id="${args.node.id}" xlink:href="#heart" x="${args.p.xb}" y="${args.p.yb}"/>`;
            if (args.cnode.ppid !== undefined && nodeData.divorced)
                args.html += `<use data-ctrl-ec-id="${args.node.id}" xlink:href="#broken-heart" x="${args.p.xa}" y="${args.p.ya}"/>`;
            if (args.cnode.isPartner && args.node.partnerSeparation === 30 && nodeData.divorced && nodeData.divorced.includes(args.cnode.id))
                args.html +=  `<use data-ctrl-ec-id="${args.node.id}" xlink:href="#broken-heart" x="${args.p.xb}" y="${args.p.yb}"/>`;
            
        });

        setFamilyObject(family);

      }
    }, [treeRef]);

    return (
        <div className='page-container'>
            <div className='page-header'>
                <h2>Afstammelingen van Antoon van der Geest en Kee van der Poel</h2>
                <p> 
                    Deze stamboom begint bij onze voorouders, Antoon van der Geest en Kee van der Poel, en beslaat vijf generaties. Deze stamboom biedt een overzicht van de namen en data die onze familiegeschiedenis vormen.
                    U kunt deze stamboom verkennen door in of uit te zoomen met de scrollfunctie of gebruik te maken van de toolbar rechtsonder. Daarnaast kunt u de zoekfunctie gebruiken om snel een naam in te voeren en die persoon in beeld te krijgen.
                    Wanneer u op een persoon klikt, wordt er meer informatie getoond, zoals de geboorteplaats en andere relevante gegevens.
                </p>
                <div className='legend'>
                <button className="download-btn" onClick={() => handlePdfDownload()}><i className='bx bxs-download'></i><p>Download als pdf</p></button>
                    <p style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g>
                                <path
                                    fill="#aeaeae"
                                    d="M438.482,58.61c-24.7-26.549-59.311-41.655-95.573-41.711c-36.291,0.042-70.938,15.14-95.676,41.694l-8.431,8.909
                                    l-8.431-8.909C181.284,5.762,98.663,2.728,45.832,51.815c-2.341,2.176-4.602,4.436-6.778,6.778
                                    c-52.072,56.166-52.072,142.968,0,199.134l187.358,197.581c6.482,6.843,17.284,7.136,24.127,0.654
                                    c0.224-0.212,0.442-0.43,0.654-0.654l187.29-197.581C490.551,201.567,490.551,114.77,438.482,58.61z"/>
                            </g>
                        </svg>
                        = Partners
                    </p>
                    <p style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g fill="#aeaeae">
                                <path fill="#E4E9F7" d="M438.482,58.61c-24.7-26.549-59.311-41.655-95.573-41.711c-36.291,0.042-70.938,15.14-95.676,41.694l-8.431,8.909  l-8.431-8.909C181.284,5.762,98.663,2.728,45.832,51.815c-2.341,2.176-4.602,4.436-6.778,6.778 c-52.072,56.166-52.072,142.968,0,199.134l187.358,197.581c6.482,6.843,17.284,7.136,24.127,0.654 c0.224-0.212,0.442-0.43,0.654-0.654l187.29-197.581C490.551,201.567,490.551,114.77,438.482,58.61z"/>
                                <path fill="#aeaeae" d="M473.7 73.8l-2.4-2.5c-46-47-118-51.7-169.6-14.8L336 159.9l-96 64 48 128-144-144 96-64-28.6-86.5C159.7 19.6 87 24 40.7 71.4l-2.4 2.4C-10.4 123.6-12.5 202.9 31 256l212.1 218.6c7.1 7.3 18.6 7.3 25.7 0L481 255.9c43.5-53 41.4-132.3-7.3-182.1z"/>
                            </g>
                        </svg>
                        = Ex-partners
                    </p>
                </div>
            </div>
            
            <div ref={treeRef} className='' style={{ minHeight: '100%', height: '100%'}}></div>
        </div>
      );
  };
  
  export default FamTree;