import React, { useRef } from "react";
import jsPDF from 'jspdf';



const NewsPaper = () => {
  const newspaperRef = useRef(null);


  const generatePdfFromNewspaper = () => {
    var element = newspaperRef.current; // Referentie naar het HTML-element
    const date = document.getElementById('publish-date').getAttribute("data-value");

    const originalWidth = element.style.width;
    const originalMaxWidth = element.style.maxWidth;

    // Stel tijdelijk de breedte in op 1200px
    element.style.width = '1200px';
    element.style.maxWidth = '1200px';
  
    // Gebruik getBoundingClientRect voor nauwkeurige hoogte
    const elementHeight = element.getBoundingClientRect().height; // Werkelijke hoogte van het element
    const a4Width = 595.28; // A4 breedte in punten

      // Schaalfactor voor het element
      const scale = a4Width / 1280;
      const pdfHeight = elementHeight * scale + 10;

    // Maak een nieuw jsPDF-document met A4 breedte en de werkelijke hoogte van het element
    var doc = new jsPDF({
      orientation: 'portrait',
      unit: 'pt', // punten
      format: [a4Width, pdfHeight], // Gebruik de hoogte van het element
    });
  
  
    // HTML-inhoud naar PDF converteren
    doc.html(element, {
      html2canvas: {
        scale: scale, // Pas de schaal toe
      },
      callback: function (doc) {
        // Check if the last page is empty and remove it if so
        if (doc.internal.getNumberOfPages() > 1) {
          const pageInfo = doc.internal.getPageInfo(doc.internal.getNumberOfPages());
          if (pageInfo.pageContext.objId === 0) {
            doc.deletePage(doc.internal.getNumberOfPages());
          }
        }

       // Voeg hyperlinks toe
      const links = element.getElementsByTagName('a');
      for (let i = 0; i < links.length; i++) {
        const link = links[i];
        const rect = link.getBoundingClientRect(); // Haal de positie van de link op
        const x = (rect.left * scale) - 35;
        const y = (rect.top * scale ) - 30;
        const width = rect.width * scale;
        const height = rect.height * scale;
        
        // Voeg de link toe aan het PDF-document
        doc.link(x, y, width, height, { url: link.href });
      }


        // Herstel de originele breedte
        element.style.width = originalWidth;
        element.style.maxWidth = originalMaxWidth;

        doc.save(`${date}_de_geestverwanten.pdf`);
      },
    });
  };
  

  return (
    <section className="home-section">
      <div className="home-content">
        {/* <div className="download-btn-container">
          <button className="download-btn" onClick={() => generatePdfFromNewspaper()}><i className='bx bxs-download'></i><p>Download als pdf</p></button>
        </div> */}
        <div ref={newspaperRef} className="newspaper-container" id="main-content">

          <div className="newspaper-header">
            <h1>Nieuwsbrief 
              {/* <p>
                Hoe moet onze nieuwbrief heten? Stuur een email naar redactie@familievdgeest.nl of vul het  <a href="/contact-form?subject=Nieuwsbrief naam inzending" className="link">contactformulier</a> in.
              </p> */}
            </h1> 
            <div id="publish-date" data-value="2024-10-18" className="newspaper-date">Vrijdag, 18 oktober, 2024</div>
          </div>

        <div className="article-section article-section-1-2 first">
          <div className="article">
            
            <img
              src={require('../assets/images/foto_opdracht/033-034.png')}
              alt="Foto opdracht 2.2"
              style={{width: '100%'}}
            />
            <p><strong>p.202:</strong> Eind november 1972 bezoeken vader Antoon en moeder Kee zes weken lang hun zoon Kees in Papua New Guinea. Een onvergetelijke ontmoeting met een andere cultuur, zoals deze foto laat zien. Vermoedelijk was Antoon de fotograaf. De remake mag er zijn!</p>
          </div>
          <div className="article">
            <h2 className="first-title">Geslaagde familiedag 6 oktober 2024</h2>
            <h5 className="author">Sjaak van der Geest</h5>
            <p> 
            Een grote eer om deze terugblik op een heerlijke dag te mogen schrijven. Er waren 50 à 60 deelnemers en degenen die er niet waren hadden al lang te voren iets anders gepland. Ze zullen de haren uit hun hoofd trekken als ze lezen hoe hartverwarmend deze reünie was. Bijzonder was ook dat op Dick na de hele eerste generatie ondanks lichamelijke problemen aanwezig kon zijn. De welgebekte ceremoniemeester Miriam (een aardje naar haar vaartje, of ze dat leuk vindt of niet) kondigde aan dat er deze keer geen competitie tussen partijen zou zijn. Politiek correct (er is al genoeg oorlog), maar ik wil er toch bij opmerken, dat de competities die we vorige jaren voerden strontgezellig waren en helemaal niet oorlogszuchtig. Denk aan de fanatieke pret op het strand bij Wijk aan Zee en de opdrachten van vorig jaar, zoals de koe die uit het water getrokken moest worden of de biefstuk die met een slagersmes uit een vrijwillig slachtoffer gesneden werd. Maar de opdracht van een remake van een foto uit Vlees & Geest was geniaal bedacht, multigenerationeel en leuk om uit te voeren (zie de collage van Lonneke en de aangeklede versie in deze Nieuwsbrief). Van mij mag dat familieboek ook de komende jaren een rol spelen op de reünie. De vellen met namen van de ontbrekende generatie(s), pasten precies in die V&G-gedachte. Daar zullen we in de toekomst meer van horen.
            <br/>
            De culinaire bijdragen waren indrukwekkend (zie onder meer de professionele taartenrevue van Olav) en mogen wat mij betreft voor altijd in het programma blijven. Bewust was er dit jaar meer tijd vrijgehouden voor (bij)praten. Ik zelf heb daarvan genoten en een paar geest(aan)verwanten beter leren kennen. Ook anderen hebben dat zo beleefd, hoorde ik om me heen. Een deel van die ‘vrije tijd’ was ik echter met mijn broer Kees naar Dick in Zoetermeer. We hadden het gevoel dat het weleens zijn afscheid kon zijn. Emotioneel! Maar ook dat was ondanks alles gezellig. Het diner, terug in het Tolhuis, was heerlijk en overvloedig en ik zag met lede ogen aan hoe de restjes in de vuilnisbak verdwenen. Dat gevoel zal wel een generatieverschil zijn, maar ik stel voor om de komende jaren een plastiek bakje mee te nemen om er een dag later nog even van te genieten of de hond/kat te verrassen. Het afscheid was moeilijk, maar dat is een goed teken. Jong van Geest, hartstikke bedankt!
            </p>
            </div>
        </div>

        <div className="divider" />

        <div className="article-section article-section-3">
          <div className="article">
            <h2 className="first-title">Taarten Review</h2>
            <h5 className="author">Olaf Zanen</h5>
            <p>
            Net als vorig jaar heb ik mij opgeofferd om alle taarten en cakes van dit jaar te proberen om jullie beste mensen het beste advies te kunnen geven waar je je het snelst aan moet wagen. Onderstaand de definitieve ranking.
            <br/>
            <strong>Omataart:</strong>
            <br/>
            Zoals in de herinnering, luchtig beslag, romige crème en uiteraard de traditionele hagelslag. Een echte blast from the past.
            <br/>
            <strong>Kruidkoek:</strong>
            <br/>
            Elk jaar verwacht en elk jaar met open armen ontvangen. Dit jaar de perfecte verhouding kruidig, koekig en sappig.
            <br/>
            <strong>Pecannotentaart:</strong>
            <br/>
            Misschien wel de grootste verrassing van dit jaar, de zoete pecannotentaart. Behalve zoet ook erg compact, in een verhouding waar een baklava jaloers op zou zijn.
            <br/>
            <strong>Citroen maanzaadcake:</strong>
            <br/>
            Heerlijke combinatie van frisse cake met een zoet glazuurtje (pak zeker die mét glazuur!)
            <br/>
            </p>
          </div>
          <div className="article no-break">
          <p>
            <strong>Pompoencake:</strong>
            <br/>
            Wederom een topcombinatie van kruidige cake en lekker snotterige topping, en dan bedoel ik snot in de positieve zin
            <br/>
            <strong>Aarbei kwarktaart:</strong>
            <br/>
            Een boven verwachting scorende taart. Ondanks het aan Koopmas uitbestede vakmanschap een lekker gebalanceerd taartje.
            <br/>
            <strong>Tiramisu:</strong>
            <br/>
            Een evergreen op het toetjesbuffet. Altijd lekker maar leunt wel zwaar in de mascarpone ten opzichte van de koffiecake.
            <br/>
            <strong>Spook slagroom kerstaart:</strong>
            <br/>
            Met afstand de mooiste taart (in ieder geval totdat hij werd aangesneden) maar de zoetigheid en fondant wogen wel wat zwaar op de maag, zeker na een stuk of 6 eerdere stukjes taart.
            <br/>
            <strong>Bananenbrood:</strong>
            <br/>
            Late binnenkomer maar toch zeker het proberen waard. Waarschijnlijk de gezondste maar minder uitgesproken en sappig dan de concurrentie.
            <br/>

            <strong>Citroen maanzaad cupcake:</strong>
            <br/>
            Na de zoetigheid van de slagroom kerstaart viel de smaak van deze taart een beetje weg. Zorg voor een optimale smaaksensatie voor een palette cleanser van tevoren.
            </p>
          </div>


          <div className="article">
            <img
              src={require('../assets/images/foto_opdracht/035-036.png')}
              alt="Foto opdracht 1"
            />
            <p><strong>p.10:</strong> Sten van der Geest kwam erachter dat hij wel erg veel op zijn oud oom Jan van der Geest lijkt.</p>
            <img
              src={require('../assets/images/foto_opdracht/037-038.png')}
              alt="Foto opdracht 2.2"
            />
            <p><strong>p.265:</strong> Een schattige Ria op de ‘bewaarschool’. Maar Lara is niet minder schattig!</p>
            {/* <h5 className="author">Links: Ria van der Geest; rechts: Lara Verlinden</h5> */}
          </div>

        </div>

        <div className="divider"/>

        <div className="article-section article-section-3">
          <div className="article no-break">   
            <img src={require('../assets/images/foto_opdracht/003-004.png')} alt="Foto opdracht 2.2"/>
            <p><strong>p.51:</strong> Bij gelegenheid van de zilveren bruiloft van Jan en Ria in 1986 wordt deze staatsiefoto gemaakt van de drie dochters, Lonneke, Judith en Corinne.</p>
          </div>

          <div className="article">
            <h2>Correspondenten gezocht</h2>
            <p>
            Om deze nieuwsbrief tot een succes te maken, hebben we jullie hulp nodig! Voor elke tak van de familie zijn we op zoek naar een enthousiaste correspondent. Als correspondent verzamel je elke drie maanden de leukste nieuwtjes, anekdotes, en bijzondere verhalen uit jouw familietak en stuur je deze door naar de eindredacteur. Of het nu gaat om geboortes, verjaardagen, nieuwe banen, grappige voorvallen of droevige gebeurtenissen – alle verhalen zijn welkom. Samen zorgen we ervoor dat iedereen op de hoogte blijft van elkaars leven. Heb je interesse? Vul dan het <a className="link" href="https://familievdgeest.nl/contact-form?subject=Correspondent">contactformulier</a> in of stuur een email naar redactie@familievdgeest.nl.
            </p>
          </div>

          <div className="article no-break">
            <h2>Oude koeien uit de sloot</h2>
            <p>
              In deze rubriek geven we de eerste generatie, onze ouders, grootouders en overgrootouders, de kans om hun verhalen met ons te delen. Verhalen die door de jaren heen misschien in de vergetelheid zijn geraakt, maar vol zitten met waardevolle herinneringen, lessen en bijzondere momenten.
            </p>
          </div>

        </div>

        <div className="article-section article-section-1-2">
          
         <div className="article">
            <h2 className="first-title">Privacy verklaring</h2>
            <p>
              Om de privacy van onze familieleden te waarborgen, is deze pagina beschermd met een wachtwoord. Mocht je toch liever niet willen dat je naam wordt genoemd of dat je foto wordt geplaatst vanwege privacyredenen, dan kun je dit eenvoudig doorgeven door het <a className="link" href="https://familievdgeest.nl/contact-form?subject=Privacy">contactformulier</a> in te vullen of een e-mail te sturen naar redactie@familievdgeest.nl. Wij zullen hier uiteraard rekening mee houden. 
            </p>
        </div>

          <div className="article">
             <div className="article">
                 {/* <h2>Oproep</h2>
                  <p>
                    Verzin een creatieve naam voor onze nieuwsbrief. Wij zijn nog op zoek naar een originele naam voor onze nieuwsbrief. Weet jij een goede naam voor ons? Stuur ons dan een e-mail naar redactie@familievdgeest.nl of vul het <a className="link" href="/contact-form?subject=Nieuwsbrief naam">contactformulier</a> in.
                  </p> */}

              </div>

            <img src={require('../assets/images/foto_opdracht/018-019.png')} alt="Foto opdracht 2.2" width={'100%'} />
            <p><strong>p.139:</strong> De Willibrordusschool in Leidschendam, waar vijf van de zes zonen hun schoolcarrière begonnen, om vandaar naar de Aloysiusschool in Den Haag te gaan waar het niveau volgens hun ouders hoger lag.            </p>
          </div>
        
        </div>

        <div className="article-section article-section-1">
          <div className="article no-break">
            <h2>Bedenk een unieke naam voor onze nieuwsbrief</h2>
            <p>
              We zijn druk bezig om onze nieuwsbrief vorm te geven, maar één belangrijk detail ontbreekt nog: een pakkende naam! En daar hebben we jouw hulp bij nodig. Heb jij een creatief idee voor een naam? Laat het ons weten!
              Stuur je suggestie via e-mail naar redactie@familievdgeest.nl of vul snel het <a className="link" href="https://familievdgeest.nl/contact-form?subject=Nieuwsbrief naam">contactformulier</a> in.
            </p>
          </div>
        </div>

        <div className="divider" />

        <div className="article-section article-section-1">
          <div className="article">
            <h2 className="first-title" style={{margin: 0}}>Foto opdracht inzendingen</h2>
            <p>Dit jaar geen strijd tussen groepen om de hoofdprijs maar gewoon leuke foto-remakes uit Vlees en Geest  voor de gezelligheid, aldus opperstalmeester Miriam. Hieronder de resultaten naast de oorspronkelijke foto’s.</p>
          </div>
          
        </div>
        
        <div className="article-section article-section-1-2">
          <div className="article no-break">
            <img src={require('../assets/images/foto_opdracht/005-006.png')} alt="Foto opdracht 2.2" width={'100%'} />
            <p><strong>p.52:</strong> Op de bruiloft van Paul en Cock treedt broer Koos op als Cock die met haar winterhiel in een emmer met zelf geproduceerde urine zit. Om precies te zijn: ’s morgens op je hurken boven een emmertje over je hielen plassen. Koos zingt een van zijn succesnummers: “Ik ben een arme ziel. Ik heb een winterhiel…. ”.</p>
          </div>
          <div className="article no-break">
            <img src={require('../assets/images/foto_opdracht/009-010-011.png')} alt="Foto opdracht 2.2" width={'100%'} />
            <p><strong>p.87:</strong> Toen deze foto werd opgenomen in Vlees en Geest, ontdekte Dick dat er behalve Jan, Kees en Koos nog een klein meisje tussen Jan en Kees zat geklemd: Annie. Haar witte mutsje is duidelijk te zien. Maar de remakers hebben haar ook niet gezien.</p>
          </div>
        </div>

        <div className="article-section article-section-2-1">
          <div>
              <img src={require('../assets/images/foto_opdracht/026-027-028.png')} alt="Foto opdracht 2.2" width={'100%'} />
              <p><strong>p.237:</strong> Slager Antoon van der Geest poseert voor de winkel met de ‘Paaskoe’ en zijn twee knechten. Kleine Kees gluurt achter de winkeldeur naar de fotograaf, wat in een van de twee remakes goed te zien is (rond 1938).</p>
          </div>
          <div>
            <img src={require('../assets/images/foto_opdracht/016-017.png')} alt="Foto opdracht 2.2" width={'100%'} />
            <p><strong>p.129:</strong> Sjakie op de ‘bewaarschool’ Wat een lief jongetje (1949). En wat te zeggen van Kay op, de remake?</p>
          </div>
        </div>

        <div className="article-section article-section-1-2">
          <div className="article no-break">
            <img src={require('../assets/images/foto_opdracht/014-015.png')} alt="Foto opdracht 2.2" width={'100%'} />
            <p><strong>p.119:</strong> Annie rijdt haar broertje Dickie. Dat moet in de oorlog geweest zijn rond 1943. De Veursestraatweg was toen nog een kale boel. Waarschijnlijk is de foto gemaakt door Vader Antoon, die vanaf het allereerste begin foto’s maakte en die zelf ontwikkelde en afdrukte in de wc. In de remake zit Annie zelf in het rijtuigje. Het leven is een cirkel.</p>
          </div>
          <div className="article no-break">
          <img src={require('../assets/images/foto_opdracht/001-002.png')} alt="Foto opdracht 2.2" width={'100%'} />
          <p><strong>p.47:</strong> Kees wordt met veel pracht en praal in het dorp ingehaald als pas gewijde priester en in het Patronaatsgebouw is een groot feest (1959).Links van Kees wethouder Groenewegen, vader van Paul, die de burgemeester vertegenwoordigt.          </p>
          </div>
        </div>

        <div className="article-section article-section-2-1">
          <div className="article no-break">
            <img src={require('../assets/images/foto_opdracht/007-008.png')} alt="Foto opdracht 2.2" width={'100%'} />
            <p><strong>p.84:</strong> Koos was fotogeniek met zijn stoere sigaar. “Wees niet boos op Koos,” schrijft hij in Vlees en Geest.</p>
          </div>
          <div className="article no-break">
          <img src={require('../assets/images/foto_opdracht/020-021.png')} alt="Foto opdracht 2.2" width={'100%'} />
          <p><strong>p.152: Klassieke Omataart</strong> Rimke kijkt kritisch naar de beroemde Omataart, die Oma net gebracht heeft voor haar verjaardag in het huisje in de Blauwe polder. Buiten staat de witte Volvo van Opa.</p>
          </div>
        </div>
        
        <div className="article-section article-section-1-2">
          <div className="article no-break">
          <img src={require('../assets/images/foto_opdracht/024-025.png')} alt="Foto opdracht 2.2" width={'100%'} />
          <p><strong>p.235:</strong> Oma leest een gedicht voor op Ria’s 50e verjaardag. Ze stond bekend om haar dichtkunst en schreef talloze levenslopen en drinkliedjes op rijm. Daan assisteert met de microfoon en mocht dat bijna 30 jaar later opnieuw doen in de remake.</p>
          </div>
          <div className="article no-break">
            <img src={require('../assets/images/foto_opdracht/012-013.png')} alt="Foto opdracht 2.2" width={'100%'} />
            <p><strong>p.100:</strong> Op zaterdagen ging Opa Toon altijd met kleine Rob naar de koeien. Deze keer waren Kees en Ria Romijn er ook bij. Het lijkt of Rob aan zijn ome Kees aanwijst waar de melk vandaan komt. Jammer dat opa Antoons sigaar ontbreekt tussen de lippen van Susan</p>
          </div>
        </div>

        <div className="article-section article-section-2-1">
          <div className="article no-break">
            <img src={require('../assets/images/foto_opdracht/031-032.png')} alt="Foto opdracht 2.2" width={'100%'} />
            <p><strong>p.261:</strong> Dit plaatje mocht niet ontbreken: Antoon en Kee op de motor. Geen helmen maar een alpinopet en een elegante hoofddoek.</p>
          </div>
          <div className="article no-break">
            <img src={require('../assets/images/foto_opdracht/029-030.png')} alt="Foto opdracht 2.2" width={'100%'} />
            <p><strong>p.250:</strong> Teenager Cock poseert met een boek en Floor doet hetzelfde met Vlees en Geest, maar wat ontbreekt is de schaduw van de fotograaf (vader Antoon).</p>
          </div>
        </div>

          <div className="newspaper-footer">
            <p>&copy; 2024 GraaffWorks. All rights reserved. <a style={{color: '#999', textDecoration: 'underline'}} href="https://familievdgeest.nl/privacy-policy">Privacy Policy</a></p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsPaper;
