
const NotFound = () => {
  return (
    <div className="page-container not-found">
        <h1>404</h1>
        <h2>Under Construction</h2>
        <p>The page you're looking for is either under construction or doesn't exist... yet.</p>
    </div>
  );
}

export default NotFound