import React from 'react'
import { Navigate } from 'react-router-dom'

const checkAuthentication = () => {
    const auth = localStorage.getItem('auth');
    if (auth) {
        const authObj = JSON.parse(auth);
        if (authObj.authenticated && authObj.timeStamp + 300000 > Date.now()) {
            return true;
        } else {
            localStorage.removeItem('auth');
            return false;
        }
    } else {
        localStorage.removeItem('auth');
    }
}
const NoAuthRoute = ({children}) => {
    if (checkAuthentication()) {
        return <Navigate to="/" />
    }
    else {
        return children
    }
}

export default NoAuthRoute;