import React from "react"
import { useNavigate } from "react-router-dom";

const Books = () => {
    // const navigate = useNavigate();

    return (
        <div className="page-container">
            <div className='page-header'>
                <h2>Boeken</h2>
                <p> 
                    
                </p>
            </div>
            <div className="page-content books-container">
                <div className="book-container">
                    <img src={require('../assets/images/books/vlees-geest.png')} alt="Vlees & Geest" style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                </div>
                <div className="book-container">
                    <img src={require('../assets/images/books/geestverwanten.png')} alt="Geestverwanten" style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                </div>
            </div>
        </div>
    );
};

export default Books;