import React, { useState } from "react";
import './login.css';
const Login = () => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.jannekedegraaff.nl/api/geesten/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
      });
      const data = await response.json();
      if (data.success) {
        localStorage.setItem('auth', JSON.stringify({authenticated: true, timeStamp: Date.now(), role: data.role}));
        window.location.href = '/';
      } else {
        setError(data.message);
      }
    } catch (error) {
        console.error(error);
        setError('An error occurred. Please try again later.');
    }
}

return(
    <div className="page-container" style={{paddingTop: '100px'}}>
      <div className="logo"><img src="/darkblue.png" alt="Logo" width={150} style={{position: 'absolute', top: '10px', left: '10px'}}/></div>
        <form className="login-form" onSubmit={handleSubmit}>
            <h2>Vul wachtwoord in</h2>
            <p>Om toegang te krijgen tot de nieuwsbrief moet u het wachtwoord invoeren.</p>
            <div className="form-group">
                <label htmlFor="password">Wachtwoord</label>
                <input 
                    type={
                        showPassword
                            ? "text"
                            : "password"
                    } 
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <div className="checkbox-wrapper-13">
                    <input id="c1-13" type="checkbox" onClick={() => setShowPassword(!showPassword)}/>
                    <label htmlFor="c1-13">Toon wachtwoord</label>
                </div>
            </div>
            {error && <div className="error-message">{error}</div>}
            <div className="form-group">
                <button type="submit">Inloggen</button>

            </div>
        </form>

    </div>
)

}

export default Login;