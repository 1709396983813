import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import 'boxicons/css/boxicons.min.css';

const Overlay = ({ isVisible, onClick }) => (
    <div 
      className={`overlay ${isVisible ? 'visible' : ''}`} 
      onClick={onClick}
    ></div>
  );


function SideBar() {
  const [isSidebarClosed, setIsSidebarClosed] = useState(true);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarClosed(!isSidebarClosed);
  };

  useEffect(() => {
    setIsSidebarClosed(true);
  }, [location]);

  /**
   * Toggle the showMenu class on the parent element of the arrow clicked. This
   * class is used to show or hide the sub-menu items.
   * @param {MouseEvent} e The event object from the clicked arrow.
   */
  const handleArrowClick = (e) => {
    const parent = e.target.parentElement.parentElement;
    parent.classList.toggle("showMenu");
  };

  return (
    <>
        <div className={`sidebar ${isSidebarClosed ? "close" : ""}`}>
        <div className="logo-details">
            {isSidebarClosed ? (
                <i className='bx bx-menu' onClick={toggleSidebar}></i>
            ) : (
                <i className='bx bx-x' onClick={toggleSidebar}></i>
            )}
            <span className="logo_name"><img src="/logo_white.png" width={150}/></span>
        </div>
        <ul className="nav-links">
            <li>
                <Link to="/">
                    <i className='bx bx-news' ></i>
                    <span className="link_name">Nieuwsbrief</span>
                </Link>
                <ul className="sub-menu blank">
                    <li>
                    <Link className="link_name" to="/">Nieuwsbrief</Link>
                    </li>
                </ul>
            </li>
            {/* <li>
                <Link to="/stamboom">
                    <i className="bx bx-sitemap"></i>
                    <span className="link_name">Stamboom</span>
                </Link>
                <ul className="sub-menu blank">
                    <li>
                    <Link className="link_name" to="/stamboom">Stamboom</Link>
                    </li>
                </ul>
            </li> */}
            <li>
            <div className="iocn-link">
                <a href="/archive">
                <i className="bx bx-history"></i>
                <span className="link_name">Archief</span>
                </a>
                <i className="bx bxs-chevron-down arrow" onClick={handleArrowClick}></i>
            </div>
            <ul className="sub-menu">
                <li><a className="link_name" href="/archive">Archief</a></li>
                <li><a href="/archive?year=2024">2024</a></li>
            </ul>
            </li>
            <li>
                <div className="iocn-link">
                    <a href="/books">
                    <i className='bx bx-book-bookmark'></i>
                    <span className="link_name">Boeken</span>
                    </a>
                    <i className="bx bxs-chevron-down arrow" onClick={handleArrowClick}></i>
                </div>
                <ul className="sub-menu">
                    <li><a className="link_name" href="/books">Boeken</a></li>
                    <li><a href="/books">Vlees & Geest</a></li>
                    <li><a href="/books">Geest verwanten</a></li>
                </ul>
            </li>
            <li>
                <Link to="/contact-form">
                    <i className='bx bx-message-detail'></i>
                    <span className="link_name">Contact formulier</span>
                </Link>
                <ul className="sub-menu blank">
                    <li>
                    <Link className="link_name" to="/contact-form">Contact formulier</Link>
                    </li>
                </ul>
            </li>
            <li>
            <div className="profile-details">
                <div className="profile-content"></div>
                <div className="name-job">
                <div className="profile_name">E-mail:</div>
                <div className="job">redactie@familievdgeest.nl</div>
                </div>
                <i className='bx bx-code-alt'></i>
            </div>
            </li>
        </ul>
        </div>
        <Overlay isVisible={!isSidebarClosed} onClick={toggleSidebar} />
    </>
  );
}

export default SideBar;
