import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import NewsPaper from "./pages/newspaper";
import SideBar from "./components/sidebar";
import "./App.css";
import FamTree from "./pages/familytree";
import Login from "./pages/login";
import AuthRoute from "./components/authRoute";
import NoAuthRoute from "./components/noAuthRoute";
import NotFound from "./pages/notFound";
import ContactForm from "./pages/contactForm";
import Books from "./pages/books";
// import PDFBookViewer from "./components/pdfBookViewer";
import Archive from "./pages/archive";
import PrivacyPolicy from "./pages/privacyPolicy";

function App() {
  return (
    <Router>
      <SideBar/>
        <Routes>
          <Route path="/login" element={<NoAuthRoute><Login /></NoAuthRoute>} />
          {/* <Route path="/stamboom" element={<AuthRoute><FamTree /></AuthRoute>} /> */}
          <Route path="/contact-form" element={<ContactForm />} />
          <Route path="/books" element={<AuthRoute><Books /></AuthRoute>} />
          <Route path="/archive" element={<AuthRoute><Archive/></AuthRoute>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* <Route path="/books/vlees-geest" element={<AuthRoute><PDFBookViewer /></AuthRoute>} /> */}
          <Route path="/" element={<AuthRoute><NewsPaper /></AuthRoute>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
    </Router>
  );
}

export default App;