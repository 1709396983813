import React, { useState, useEffect } from 'react';
import { Accordion } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import './archive.css';
import { useSearchParams } from 'react-router-dom';

const newspapers = {
  2024: [
      { title: '18 Oktober 2024', filename: '2024-10-18_de_geestverwanten.pdf', url: require('../assets/archive/2024/2024-10-18_de_geestverwanten.pdf') },
    ],
};

const Archive = () => {
  const [activeYear, setActiveYear] = useState(Object.keys(newspapers)[0]);
  const [activeKey, setActiveKey] = useState(0); // houdt bij welk paneel open is
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Haal het jaar uit de URL (bijvoorbeeld: ?year=2024)
    const yearFromUrl = searchParams.get('year');

    if (yearFromUrl && newspapers[yearFromUrl]) {
      setActiveYear(yearFromUrl);
      console.log(yearFromUrl, Object.keys(newspapers).reverse().indexOf(yearFromUrl));
      setActiveKey(Object.keys(newspapers).reverse().indexOf(yearFromUrl));
    }
  }, [activeYear]);

  const handleActiveKey = (key) => {
    setActiveKey(key);
    setActiveYear(Object.keys(newspapers).reverse()[key]);
    setSearchParams({ year: Object.keys(newspapers).reverse()[key] });
  };

  return (
    <div className="page-container">
      <div className='page-header'>
        <h2>Nieuwsbrief Archief</h2>
        <p>Welkom bij het archief van onze familienieuwsbrieven. Hier vind je een verzameling van alle nieuwsbrieven die in de afgelopen jaren zijn uitgebracht. Het archief is ingedeeld per jaar, zodat je eenvoudig kunt bladeren door de verschillende edities. Klik gerust op een jaartal om de bijbehorende nieuwsbrieven te openen en te downloaden.</p>
      </div>
      <div className='acoordion-container'>
        <Accordion defaultActiveKey={activeKey} activeKey={activeKey} onSelect={handleActiveKey} bordered>
        {Object.entries(newspapers).reverse().map(([year, papers], key) => (
          <Accordion.Panel header={year} eventKey={key} key={key}>
            <ul>
              {papers.map((paper, index) => (
                <li key={index}><a download={paper.filename} href={paper.url}>{paper.title}</a></li>
              ))}
            </ul>
        </Accordion.Panel>
        ))}
        </Accordion>
      </div>
    </div>
  );
};

export default Archive;
